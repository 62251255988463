#invoice-landpage div.zalacznik {
   margin: 2em 0;
}

#invoice-landpage .tlo-zalacznika {
   background-color: #ffffdd;
}

#invoice-landpage table {
   border-collapse: collapse;
}

#invoice-landpage .normalna,
#invoice-landpage .tytul-sekcja-blok {
   width: 100%;
}

#invoice-landpage .naglowek {
   background-color: #ccddff;
}

#invoice-landpage .kod-formularza {
   font-size: 2em;
   font-weight: bold;
}

#invoice-landpage .naglowek .wartosc {
   font-weight: bold;
}

#invoice-landpage .tytul {
   font-size: 2em;
   font-weight: bold;
   text-align: center;
}

#invoice-landpage .tytulformularza {
   font-size: xx-large;
   font-weight: bold;
   text-align: center;
}

#invoice-landpage .okres {
   display: block;
   margin: 1em;
   font-size: 1.2em;
   text-align: center;
   vertical-align: top;
   clear: both;
}

#invoice-landpage .data {
   border: 1px solid black;
   padding: 2px 1.5em;
   background-color: white;
   font-weight: bold;
   margin: 0 2em;
}

#invoice-landpage .obok-daty {
   text-align: right;
   display: inline-block;
   width: 45%;
}

#invoice-landpage .objasnienie {
   border: 2px solid black;
   padding: 0.25em 0.5em;
   font-size: 0.8em;
}

#invoice-landpage .zalacznik-objasnienie {
   background-color: #dddddd;
   border: 2px solid black;
   font-size: 0.8em;
   width: 99.5%;
}

#invoice-landpage .prawne {
   background-color: #dddddd;
   border: 2px solid black;
   font-size: 0.8em;
}

#invoice-landpage .prawne .etykieta {
   min-width: 9em;
}

#invoice-landpage .wypelniane {
   background-color: white;
}

#invoice-landpage .niewypelniane,
#invoice-landpage .ukryte {
   background-color: #dddddd;
}

#invoice-landpage .pouczenia td {
   background-color: white;
   border: 2px solid black;
}

#invoice-landpage .tytul-sekcja-blok {
   background-color: #ddddff;
   border: 2px solid black;
   text-transform: uppercase;
   width: 99.5%;
}

#invoice-landpage h1 {
   font-size: 1.4em;
   font-weight: bold;
   text-align: center;
   text-transform: uppercase;
}

#invoice-landpage h2 {
   text-align: left;
   font-size: 1.2em;
   font-weight: bold;
}

#invoice-landpage h3 {
   text-align: left;
   font-size: 1.2em;
   font-weight: normal;
}

#invoice-landpage h4 {
   text-align: left;
   font-size: 1em;
   font-weight: normal;
}

#invoice-landpage h1,
#invoice-landpage h2,
#invoice-landpage h3,
#invoice-landpage h4 {
   font-family: "Arial", sans-serif;
   margin: 0;
   padding: 1px;
}

#invoice-landpage .wypelniane {
   border: 1px solid black;
   font-size: 1.2em;
   padding: 1px;
   vertical-align: top;
   text-align: left;
}

#invoice-landpage .puste {
   background-color: #dddddd;
   border: 1px solid black;
   font-size: 0.8em;
   padding: 1px;
   height: 2em;
   vertical-align: top;
}

#invoice-landpage .puste2 {
   background-color: #dddddd;
   border: 1px solid black;
   font-size: 1.2em;
   padding: 1px;
   height: 2em;
   text-align: center;
}

#invoice-landpage .niewypelnianeopisy {
   background-color: #dddddd;
   border: 1px solid black;
   font-size: 0.8em;
   padding: 1px;
   height: 2em;
   vertical-align: top;
   text-align: left;
}

#invoice-landpage .center {
   background-color: #dddddd;
   border: 1px solid black;
   font-size: 0.8em;
   padding: 1px;
   height: 2em;
   vertical-align: top;
   text-align: center;
}

#invoice-landpage .niewypelniane,
#invoice-landpage .pouczenia td,
#invoice-landpage .taknie {
   border: 1px solid black;
   font-size: 0.8em;
   font-weight: bold;
   padding: 1px;
   height: 2em;
   vertical-align: top;
   text-align: center;
}

#invoice-landpage .kwota {
   text-align: right;
   padding-right: 0.2em;
}

#invoice-landpage .opisrubryki {
   font-size: 0.5em;
   margin-bottom: 0.2em;
   text-align: left;
   font-weight: bold;
   text-transform: none;
}

#invoice-landpage .opis-tekstowy {
   font-size: 0.7em;
   margin-bottom: 0.2em;
   font-weight: bold;
   text-transform: none;
}

#invoice-landpage .czcionka td,
#invoice-landpage .czcionka th {
   font-size: 0.9em;
   height: 1em;
}

#invoice-landpage .nazwa-dla-kodu {
   font-size: 0.9em;
}

#invoice-landpage table.normalna,
#invoice-landpage table.pouczenia,
#invoice-landpage table.sposob-opodatkowania {
   width: 100%;
   margin: 0;
   border: 1px solid black;
}

#invoice-landpage h2.tekst {
   text-align: center;
   margin: 0.5em auto;
}

#invoice-landpage .pouczenie {
   padding: 0.5em 2em;
   text-align: center;
}

#invoice-landpage p {
   padding: 0;
   margin: 0.2em 0;
}

#invoice-landpage .objasnienie ol {
   list-style-type: decimal;
}

#invoice-landpage .objasnienie ol ul {
   list-style-type: disc;
}

#invoice-landpage .taknie {
   text-transform: uppercase;
   text-align: center;
}

#invoice-landpage .normalna-tekstowa {
   width: 100%;
   border: 0px;
}

#invoice-landpage .tekst {
   text-align: center;
}

#invoice-landpage .wartosc {
   vertical-align: top;
}

#invoice-landpage .miesiac {
   border: 1px solid black;
   font-size: 0.8em;
   font-weight: bold;
   padding: 1px;
   height: 2em;
   vertical-align: middle;
   text-align: center;
   background-color: #dddddd;
}

#invoice-landpage .podtytul {
   font-size: medium;
}

#invoice-landpage .tytulzalacznika {
   font-size: x-large;
   font-weight: bold;
   text-align: center;
}

#invoice-landpage .opis {
   font-size: 1em;
   padding: 4px;
}

#invoice-landpage .opis-tabela {
   font-size: 1em;
   padding: 4px;
   border: 0;
}

#invoice-landpage .dodatkowe {
   background-color: white;
   border: 0;
}

#invoice-landpage .wypunktowane {
   font-size: 1em;
   padding: 1px;
   vertical-align: top;
}

#invoice-landpage .tlo-formularza,
#invoice-landpage .tlo-zalacznika {
   padding-bottom: 1px;
}

@media print {
   #invoice-landpage .lamstrone {
      page-break-before: always;
   }

   #invoice-landpage .sekcja {
      page-break-inside: avoid;
   }
}

#invoice-landpage .tlo-formularza {
   background-color: #d3d3d3;
}

#invoice-landpage table.wrapping,
#invoice-landpage .break-word {
   white-space: normal !important;
   word-wrap: break-word;
}

#invoice-landpage table {
   width: 100%;
}

#invoice-landpage .word-break {
   width: 100%;
   word-break: break-all;
}

#invoice-landpage .lewa {
   border: 1px solid black;
   font-size: 1.2em;
   padding: 1px;
   vertical-align: top;
   text-align: left;
}

#invoice-landpage .srodek {
   border: 1px solid black;
   font-size: 1.2em;
   padding: 1px;
   vertical-align: top;
   text-align: center;
}

#invoice-landpage .prawa {
   border: 1px solid black;
   font-size: 1.2em;
   padding: 1px;
   vertical-align: top;
   text-align: right;
}
