iframe {
   pointer-events: none;
}

.litepicker{
   border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
   border: 1px solid rgba(0, 0, 0, 0.23);
}

.litepicker {
   font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
   font-size: 0.9em !important;
}
.litepicker .container__months .month-item-header div>.month-item-name,
.litepicker .container__months .month-item-header div>.month-item-year {
   margin: 0;
   width: 100%;
   color: #263238;
   z-index: 1;
   outline: none;
   border: 1px solid rgba(0, 0, 0, 0.23);
   padding: 0.25em 0.5em !important;
   font-size: 0.8rem;
   min-height: 1em;
   white-space: nowrap;
   cursor: pointer;
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   background-color: #fff;
   line-height: 1em;
   letter-spacing: 0.00938em;
   border-radius: 4px;
}
.litepicker .container__months .month-item-header div>.month-item-name{
   margin-right: 0.5em;
}
.litepicker .container__months .month-item-header div>.month-item-name::-ms-expand,
.litepicker .container__months .month-item-header div>.month-item-year::-ms-expand {
   display: none;
}
.litepicker .container__months .month-item-header div>.month-item-name:focus + .focus,
.litepicker .container__months .month-item-header div>.month-item-year:focus + .focus {
   position: absolute;
   top: -1px;
   left: -1px;
   right: -1px;
   bottom: -1px;
   border: 2px solid blue;
   border-radius: inherit;
}
.litepicker .container__months .month-item-header div>.month-item-name[multiple],
.litepicker .container__months .month-item-header div>.month-item-year[multiple] {
   padding-right: 0;
   height: 6rem;
}
.litepicker .container__months .month-item-header div>.month-item-name[multiple] option,
.litepicker .container__months .month-item-header div>.month-item-year[multiple] option {
   white-space: normal;
   outline-color: blue;
}
div:has(> .month-item-name),
div:has(> .month-item-year) {
   display: flex;
   justify-content: center;
   align-items: center;
}

div:has(> .litepicker .container__months .month-item-header div>.month-item-name) {
   margin-left: 0.5em;
}
div:has(> .litepicker .container__months .month-item-header div>.month-item-year) {
   margin-right: 0.5em;
}

.litepicker .container__months .month-item-weekdays-row>div {
   font-size: 0.9rem;
   font-weight: 500;
   color: #263238;
   text-align: center;
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   background-color: #fff;
}
.litepicker[data-plugins*="ranges"][data-ranges-position="left"] > .container__main > .container__predefined-ranges{
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    background: var(--litepicker-container-months-color-bg) !important;
    box-shadow: -2px 0px 5px var(--litepicker-footer-box-shadow-color) !important;
    border-radius: 3px !important;
    z-index: 5;
   justify-content: center !important;
}
.litepicker[data-plugins*="ranges"][data-ranges-position="left"] > .container__main > .container__predefined-ranges button{
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    margin: 0.25em 0.5em;
    background-color: #fff;
    color: #263238;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    min-height: 1em;
    line-height: 1em;
    letter-spacing: 0.00938em;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
   transition: all 0.05s ease-in-out;
   min-width: 120px;

}
.litepicker[data-plugins*="ranges"][data-ranges-position="left"] > .container__main > .container__predefined-ranges button:hover{
   background-color: #1d97fa;
    color: var(--litepicker-is-start-color);
   font-weight: 450;
}

.litepicker .container__months.columns-2 {
   z-index: 10;
   border: 1px solid rgba(0, 0, 0, 0.23);
   border-radius: 3px;
}